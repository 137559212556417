<!-- 分页查询
<pager-query :pager=[分页对象] @change=[列表查询方法] />
pager page:第一页
      rows:每页条数
      count:总条数
-->

<template>
  <div class="d-paging">
    <el-pagination layout="total, sizes, prev, pager, next, jumper"
                   background
                   :pager-count="7"
                   :total="pager[props.total]"
                   :current-page.sync="pager[props.page]"
                   :page-size="pager[props.rows]"
                   :page-sizes="[10, 20, 30, 50]"
                   @size-change="onChangeSize"
                   :hide-on-single-page="false"
                   @current-change="onChangePage">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'd-paging',
  props: {
    pager: {
      type: Object,
      required: true
    },
    refresh: {
      type: Boolean
    },
    props: {
      type: Object,
      required: false,
      default: () => ({
        page: 'page', // 第几页
        rows: 'rows', // 显示条数
        total: 'count' // 总记录条数
      })
    }
  },
  // 设置绑定参数
  model: {
    prop: 'pager',
    event: 'setPager'
  },
  computed: {
    total () {
      return this.pager[this.props.total] || 0
    },
    // 检测获取到的数据是否为空
    isEmptyList () {
      return Math.ceil(this.pager[this.props.total] / this.pager[this.props.rows]) < this.pager[this.props.page]
    }
  },
  watch: {
    total () {
      // 存在记录但未获取到数据时, 重新请求
      if (this.pager[this.props.page] > 1 && this.isEmptyList) {
        this.$emit('setPager', Object.assign(this.pager, {
          [this.props.page]: this.pager[this.props.page] - 1
        }))
        this.$emit('change')
      }
    }
  },
  methods: {
    // 每页条数
    onChangeSize (rows) {
      const temp = {
        [this.props.rows]: rows,
        // 当显示条数小于或等于总条数时，重置页数
        [this.props.page]: rows <= this.total ? 1 : this.pager[this.props.page]
      }

      this.$emit('setPager', Object.assign(this.pager, temp))
      // 触发父组件查询请求
      this.$emit('change')
    },
    // 翻页
    onChangePage (page) {
      this.$emit('setPager', Object.assign(this.pager, { [this.props.page]: page }))
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
.d-paging {
  background: #fff;
  .el-pagination {
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>
