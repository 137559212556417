var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',_vm._g(_vm._b({staticClass:"el-table",attrs:{"size":"medium","data":_vm.tableData}},'el-table',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.columnData),function(column,index){return [(column.type === 'selection')?_c('el-table-column',{key:column.index,attrs:{"type":"selection","width":"55"}}):(column.positionTag)?_c('el-table-column',{key:column.index,attrs:{"prop":column.prop,"label":column.label,"width":column.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row['position'])?_c('span',[_vm._v(" "+_vm._s(scope.row[column.prop])+" "),_c('el-tag',[_vm._v("店长")])],1):_c('span',[_vm._v(_vm._s(scope.row[column.prop]))])]}}],null,true)}):(column.columnType === 'click')?_c('el-table-column',{key:index,attrs:{"prop":column.prop,"label":column.label,"width":column.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"clickstyle",on:{"click":function($event){return _vm.clickType(scope.row)}}},[_vm._v(" "+_vm._s(scope.row[column.prop])+" ")])]}}],null,true)}):(column.slot)?_vm._t(column.prop):(column.slotProp)?_c('el-table-column',{key:index,attrs:{"width":column.width,"label":column.label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t(column.prop,null,{"row":scope.row})]}}],null,true)}):(column.prop === 'operate')?_c('el-table-column',{key:index,attrs:{"prop":column.prop,"fixed":column.fixed || false,"label":column.label,"width":column.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t("handle",null,{"item":scope.row}),(scope.row.isBtnStatus || scope.row.isBtnStatus === undefined)?_c('div',{staticClass:"operateClass clickstyle"},[(
              column.type.compile &&
              (scope.row.isCompile === undefined || scope.row.isCompile)
            )?_c('span',{on:{"click":function($event){return _vm.compile(scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(
              column.type.remove &&
              (scope.row.isRemove === undefined || scope.row.isRemove)
            )?_c('span',{on:{"click":function($event){return _vm.removeEvent(scope.row)}}},[_vm._v(" 删除 ")]):_vm._e(),(
              column.type.accredit &&
              (scope.row.isAccredit === undefined || scope.row.isAccredit)
            )?_c('span',{on:{"click":function($event){return _vm.accredit(scope.row)}}},[_vm._v(" 授权 ")]):_vm._e(),(
              column.type.viewBtn &&
              (scope.row.isAccredit === undefined || scope.row.isAccredit)
            )?_c('span',{on:{"click":function($event){return _vm.viewEvent(scope.row)}}},[_vm._v(" 查看 ")]):_vm._e(),(
              column.type.printBtn &&
              (scope.row.isAccredit === undefined || scope.row.isAccredit)
            )?_c('span',{on:{"click":function($event){return _vm.printEvent(scope.row)}}},[_vm._v(" 打印 ")]):_vm._e(),(
              column.type.transfer &&
              scope.row.state === 1 &&
              scope.row.position
            )?_c('span',{on:{"click":function($event){return _vm.transfer(scope.row)}}},[_vm._v(" 转移 ")]):_vm._e(),(
              column.type.promotion &&
              (scope.row.isPromotion === undefined || scope.row.isPromotion)
            )?_c('span',{on:{"click":function($event){return _vm.positionChange(scope.row, 'top')}}},[_vm._v(" 升职 ")]):_vm._e(),(
              column.type.dimission &&
              (scope.row.isDimission === undefined || scope.row.isDimission)
            )?_c('span',{staticClass:"dimission",on:{"click":function($event){return _vm.positionChange(scope.row, 'reduce')}}},[_vm._v(" 离职 ")]):_vm._e(),(
              column.type.check &&
              (scope.row.isCheck === undefined || scope.row.isCheck)
            )?_c('span',{on:{"click":function($event){return _vm.checkTime(scope.row)}}},[_vm._v(" 审核 ")]):_vm._e(),(
              column.type.reuse &&
              (scope.row.isReuse === undefined || scope.row.isReuse)
            )?_c('span',{on:{"click":function($event){return _vm.reuseEvent(scope.row)}}},[_vm._v(" 重新退款 ")]):_vm._e()]):_vm._e()]}}],null,true)}):_c('el-table-column',{key:index,attrs:{"min-width":column.width,"prop":column.prop,"label":column.label}})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }